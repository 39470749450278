import ekkoImgEkanban from '@/components/common/img/ekko_ekanban.webp';
import ekkoImgFinder from '@/components/common/img/ekko_img_finder1.webp';
import ekkoImgLinker from '@/components/common/img/ekko_linker.webp';
import ekkoImgPicker from '@/components/common/img/ekko_picker.webp';
import ekkoImgUpdater from '@/components/common/img/ekko_updater.webp';
import { hasRequiredPermissions, Permission } from '@/context/atoms/UserPermissions';
import { upperCaseFirst } from '@/helper/utilities';
import type { ZoneIdentifier } from '@ekkogmbh/apisdk';
import {
  AutorenewRounded,
  CreateRounded,
  LinkRounded,
  SearchRounded,
  type SvgIconComponent,
  TouchAppRounded,
} from '@mui/icons-material';
import { type Route } from 'next';
import { type StaticImageData } from 'next/image';

export interface UseAppModules {
  appModules: Record<ModuleType, Module>;
  moduleRoutes: Record<ModuleType, Route>;
  getAccessibleModules: (permissions: Permission[]) => Module[];
  getTitleByPathname: (pathname: string) => string | null;
  findModuleByRouteSegment: (routeSegment: string) => Module | undefined;
  findModuleByRouteSegments: (routeSegments: string[]) => Module | undefined;
  hasRequiredPermissions: (permissions: Permission[], requiredPermissions: Permission[]) => boolean;
}

export enum ModuleType {
  PICKER = 'picker',
  LINKER = 'linker',
  FINDER = 'finder',
  UPDATER = 'updater',
  EKANBAN = 'ekanban',
}

export type ModuleRoute = Extract<Route, '/picker' | '/linker' | '/finder' | '/updater' | '/ekanban'>;

export interface Module {
  type: ModuleType;
  title: string;
  href: Route;
  permissions: Permission[];
  icon: SvgIconComponent;
  bgImage: StaticImageData;
}

export interface EntityIdentifier {
  name: string;
  coordinate: string;
  zone?: ZoneIdentifier;
}

export interface Favorite {
  type: ModuleType;
  identifier: EntityIdentifier;
}

export function useAppModules(): UseAppModules {
  const moduleRoutes: Record<ModuleType, ModuleRoute> = {
    [ModuleType.PICKER]: '/picker',
    [ModuleType.LINKER]: '/linker',
    [ModuleType.FINDER]: '/finder',
    [ModuleType.UPDATER]: '/updater',
    [ModuleType.EKANBAN]: '/ekanban',
  };

  const routeTitles: Record<ModuleRoute, string> & Partial<Record<Route, string>> = {
    '/picker': upperCaseFirst(ModuleType.PICKER),
    '/linker': upperCaseFirst(ModuleType.LINKER),
    '/finder': upperCaseFirst(ModuleType.FINDER),
    '/updater': upperCaseFirst(ModuleType.UPDATER),
    '/ekanban': upperCaseFirst(ModuleType.EKANBAN),
    '/favorites': 'Favorites',
  };

  const appModules: Record<ModuleType, Module> = {
    picker: {
      type: ModuleType.PICKER,
      title: routeTitles[moduleRoutes.picker],
      href: moduleRoutes.picker,
      permissions: [Permission.PICKER_PROFILES_READ],
      icon: TouchAppRounded,
      bgImage: ekkoImgPicker,
    },
    linker: {
      type: ModuleType.LINKER,
      title: routeTitles[moduleRoutes.linker],
      href: moduleRoutes.linker,
      permissions: [Permission.LINK_PROFILES_READ],
      icon: LinkRounded,
      bgImage: ekkoImgLinker,
    },
    finder: {
      type: ModuleType.FINDER,
      title: routeTitles[moduleRoutes.finder],
      href: moduleRoutes.finder,
      permissions: [Permission.SHELFLABELING_READ],
      icon: SearchRounded,
      bgImage: ekkoImgFinder,
    },
    updater: {
      type: ModuleType.UPDATER,
      title: routeTitles[moduleRoutes.updater],
      href: moduleRoutes.updater,
      permissions: [Permission.UPDATER_PROFILE_READ],
      icon: CreateRounded,
      bgImage: ekkoImgUpdater,
    },
    ekanban: {
      type: ModuleType.EKANBAN,
      title: routeTitles[moduleRoutes.ekanban],
      href: moduleRoutes.ekanban,
      permissions: [Permission.EKANBAN_READ],
      icon: AutorenewRounded,
      bgImage: ekkoImgEkanban,
    },
  };

  return {
    appModules,
    moduleRoutes,
    getAccessibleModules: (permissions: Permission[]): Module[] =>
      Object.values(appModules).filter((m: Module) => hasRequiredPermissions(permissions, m.permissions)),
    getTitleByPathname: (pathname: string): string | null =>
      Object.values(appModules).find((m) => pathname.startsWith(m.href))?.title ??
      Object.entries(routeTitles).find(([route]) => pathname.startsWith(route))?.[1] ??
      null,
    findModuleByRouteSegment: (routeSegment: string): Module | undefined =>
      Object.values(appModules).find((m) => m.href.replace('/', '') === routeSegment),
    findModuleByRouteSegments: (routeSegments: string[]): Module | undefined =>
      Object.values(appModules).find((m) => routeSegments.includes(m.href.replace('/', ''))),
    hasRequiredPermissions,
  };
}
