'use client';
import { apiSdkAtom } from '@/context/atoms/ApiSdk';
import { jwtAuthMechanismAtom } from '@/context/atoms/JWTAuthMechanism';
import { publicConfigAtom } from '@/context/atoms/publicConfig';
import { type SocketIOEventsMap } from '@/context/messaging/socket-io';
import { useAtom } from 'jotai';
import React, {
  createContext,
  type MutableRefObject,
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { io as ioSocket, type Socket } from 'socket.io-client';

interface SocketIOContextState {
  connected: boolean;
  connecting: boolean;
  showIndicator: boolean;
  io: MutableRefObject<Socket<SocketIOEventsMap> | null>;
  connect: () => Promise<Socket<SocketIOEventsMap>>;
  disconnect: () => Promise<void>;
}

export const SocketIOContext = createContext<SocketIOContextState | null>(null);

export function useSocketIO(): SocketIOContextState {
  const context = useContext(SocketIOContext);
  if (context === null) {
    throw new Error('useSocketIO must be used within SocketIOProvider');
  }

  return context;
}

export type SocketIOProviderProps = PropsWithChildren;

export function SocketIOProvider({ children }: SocketIOProviderProps): React.JSX.Element {
  const [api] = useAtom(apiSdkAtom);
  const [connected, setConnected] = useState<SocketIOContextState['connected']>(false);
  const [connecting, setConnecting] = useState<SocketIOContextState['connecting']>(false);
  const [showIndicator, setShowIndicator] = useState<SocketIOContextState['showIndicator']>(false);
  const io = useRef<Socket<SocketIOEventsMap> | null>(null);
  const connection = useRef<Promise<Socket<SocketIOEventsMap>> | null>(null);
  const [publicConfig] = useAtom(publicConfigAtom);
  const [jwtAuthMechanism] = useAtom(jwtAuthMechanismAtom);

  const createSocketPromise = useCallback(async () => {
    return await new Promise<Socket<SocketIOEventsMap>>((resolve, reject) => {
      const hostname = publicConfig.env.SOCKET_IO_HOST ?? window.location.hostname;
      const socketIoPort = publicConfig.env.SOCKET_IO_PORT ?? 3030;
      const socketIoServer = `//${hostname}:${socketIoPort}`;

      const socket = ioSocket(socketIoServer, {
        // @FIXME: Handle reconnection by ourselves in the future to have more
        // control over the process, as we need to resubscribe to mqtt topics
        // here for example.
        reconnection: true,
        autoConnect: false,
        // We are transferring the whole JWT authentication to the server
        // including all tokens and data, in order to establish the same level
        // of access rights as on the client.
        auth: {
          authToken: jwtAuthMechanism.authentication.authToken,
        },
      });

      socket.on('connect_error', (err) => {
        console.log('socket.io connection error');
        setConnected(false);
        setConnecting(false);
        io.current = null;
        reject(err);
      });

      socket.on('connect', () => {
        console.log('socket.io connected');
        io.current = socket;
        setConnected(true);
        setConnecting(false);
        resolve(socket);
      });

      setConnecting(true);
      socket.connect();
    });
  }, [jwtAuthMechanism.authentication.authToken, publicConfig.env.SOCKET_IO_HOST, publicConfig.env.SOCKET_IO_PORT]);

  // refactored to a single socket-io instance. as of now, we don't need multiple socket connections
  const connect = useCallback(async (): Promise<Socket<SocketIOEventsMap>> => {
    setShowIndicator(true);

    if (connection.current === null) {
      console.log('Initiating socket.io connection');
      connection.current = createSocketPromise();
    }

    try {
      return await connection.current;
    } catch (error) {
      console.error('Failed to connect to socket.io', error);
      await api.getCurrentUser();
      connection.current = createSocketPromise();
      return await connection.current;
    }
  }, [api, createSocketPromise]);

  const disconnect = async (): Promise<void> => {
    if (connection.current !== null) {
      connection.current = null;
    }

    if (io.current !== null) {
      console.log(`Disconnecting socket.io with id: "${io.current.id}"`);
      io.current.disconnect();
      io.current = null;
    }

    setConnected(false);
    setConnecting(false);
    setShowIndicator(false);
  };

  useEffect(() => {
    void (async () => {
      try {
        if (connection.current !== null) {
          await disconnect();
          await connect();
        }
      } catch (error) {
        console.error('Failed to reconnect to socket.io', error);
      }
    })();
  }, [connect, jwtAuthMechanism.authentication.authToken]);

  return (
    <SocketIOContext.Provider value={{ connected, connecting, showIndicator, io, connect, disconnect }}>
      {children}
    </SocketIOContext.Provider>
  );
}
