import { type Keyframes } from '@emotion/react';
import IconButton, { type IconButtonProps } from '@mui/material/IconButton';
import { keyframes, styled } from '@mui/material/styles';
import React from 'react';

const generateGlowKeyframes = (color: string, steps: number, maxRadius: number): Keyframes => {
  const stepPercentage = 100 / steps;
  const keyframeSteps: Record<string, { background: string }> = {};

  for (let i = 0; i <= steps; i++) {
    const percentage = i * stepPercentage;
    const radius = (i <= steps / 2 ? i : steps - i) * (maxRadius / (steps / 2));
    keyframeSteps[`${percentage.toFixed(2)}%`] = {
      background: `radial-gradient(${color}, transparent ${radius}px)`,
    };
  }

  return keyframes(keyframeSteps);
};

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) =>
    prop !== 'glow' &&
    prop !== 'glowColor' &&
    prop !== 'duration' &&
    prop !== 'steps' &&
    prop !== 'maxRadius' &&
    prop !== 'animationTimingFunction',
})<{
  glowColor: string;
  duration: number;
  steps: number;
  maxRadius: number;
  animationTimingFunction: string;
  glow: boolean;
}>(({ glowColor, duration, steps, maxRadius, animationTimingFunction, glow }) =>
  glow
    ? {
        animationName: generateGlowKeyframes(glowColor, steps, maxRadius),
        animationDuration: `${duration}ms`,
        animationTimingFunction,
        animationIterationCount: 'infinite',
      }
    : {},
);

interface GlowingIconButtonProps extends IconButtonProps {
  glow?: boolean;
  glowColor?: string;
  duration?: number;
  steps?: number;
  maxRadius?: number;
  animationTimingFunction?: string;
}

export default function GlowIconButton({
  glow = false,
  glowColor = 'rgba(232,60,63,0.5)',
  duration = 1500,
  steps = 20,
  maxRadius = 20,
  animationTimingFunction = 'ease-in-out',
  children,
  ...props
}: GlowingIconButtonProps): React.JSX.Element {
  return (
    <StyledIconButton
      glow={glow}
      glowColor={glowColor}
      duration={duration}
      steps={steps}
      maxRadius={maxRadius}
      animationTimingFunction={animationTimingFunction}
      {...props}
    >
      {children}
    </StyledIconButton>
  );
}
